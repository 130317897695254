import { useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

// Component สำหรับแสดง Legend ชาติพันธุ์
const EthnicityLegend = ({ ethnicities, visible }) => {
  const [legend, setLegend] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (!map || !ethnicities) return;

    // สร้าง legend control ถ้ายังไม่มี
    if (!legend) {
      const newLegend = L.control({ position: 'bottomright' });

      newLegend.onAdd = function() {
        const div = L.DomUtil.create('div', 'ethnicity-legend');
        div.style.backgroundColor = 'white';
        div.style.padding = '10px';
        div.style.borderRadius = '5px';
        div.style.boxShadow = '0 0 5px rgba(0,0,0,0.2)';
        div.style.lineHeight = '24px';
        div.style.maxHeight = '300px';
        div.style.overflow = 'auto';
        div.style.fontFamily = 'Tahoma, sans-serif';
        div.style.fontSize = '13px';
        div.style.minWidth = '180px';
        div.style.maxWidth = '220px';
        div.style.opacity = visible ? '1' : '0';
        div.style.transition = 'opacity 0.3s ease';
        div.style.pointerEvents = 'auto';

        // แสดงเฉพาะเมื่อมีข้อมูลชาติพันธุ์เท่านั้น
        if (ethnicities.length > 0) {
          div.innerHTML = '<div style="font-weight: bold; margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 5px; text-align: center;">ชาติพันธุ์ในพื้นที่</div>';
          
          ethnicities.forEach(ethnicity => {
            div.innerHTML += `
              <div style="display: flex; align-items: center; margin-bottom: 6px;">
                <img src="${ethnicity.iconUrl}" style="width: 20px; height: 20px; margin-right: 8px;">
                <div style="color: ${ethnicity.color}; flex: 1;">${ethnicity.name || 'ไม่ระบุ'}</div>
              </div>
            `;
          });
        }
        // ลบส่วนที่แสดง "ไม่พบข้อมูลชาติพันธุ์" ออกไป

        return div;
      };

      // เพิ่มเงื่อนไขให้แสดง legend เฉพาะเมื่อมีข้อมูลชาติพันธุ์
      if (ethnicities.length > 0) {
        newLegend.addTo(map);
        setLegend(newLegend);
      }
    } else {
      // อัพเดท legend ที่มีอยู่แล้ว
      const div = legend.getContainer();
      if (div) {
        div.style.opacity = visible ? '1' : '0';
        
        if (ethnicities.length > 0) {
          let content = '<div style="font-weight: bold; margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 5px; text-align: center;">ชาติพันธุ์ในพื้นที่</div>';
          
          ethnicities.forEach(ethnicity => {
            content += `
              <div style="display: flex; align-items: center; margin-bottom: 6px;">
                <img src="${ethnicity.iconUrl}" style="width: 20px; height: 20px; margin-right: 8px;">
                <div style="color: ${ethnicity.color}; flex: 1;">${ethnicity.name || 'ไม่ระบุ'}</div>
              </div>
            `;
          });
          
          div.innerHTML = content;
        } else {
          // ถ้าไม่มีข้อมูล ให้ซ่อน legend แทนที่จะแสดงข้อความ
          div.style.opacity = '0';
        }
      }
    }

    // Cleanup function
    return () => {
      if (legend && map) {
        map.removeControl(legend);
        setLegend(null);
      }
    };
  }, [map, ethnicities, legend, visible]);

  return null;
};

export default EthnicityLegend;