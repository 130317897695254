import React, { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

// Component to display village markers from API
const VillageMarkers = ({ onEthnicitiesLoaded }) => {
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ethnicities, setEthnicities] = useState([]);
  
  // กำหนดสีประจำแต่ละชาติพันธุ์
const ethnicityColors = {
  'จีนฮ่อ มุสลิมยุนนาน': '#FF5733',
  'ปกาเกอะญอ': '#33A1FF',
  'ม้ง': '#4CAF50',
  'มอญ รมัน รามัญ': '#9C27B0',
  'เมี่ยน อิ้วเมี่ยน': '#FF9800',
  'ไทลื้อ': '#E91E63',
  'ลาวโซ่ง ไทยโซ่ง ไทยทรงดำ ไทดำ ไตดำ โซ่ง': '#795548',
  'ลาหู่ ละหู่ ลาฮู': '#CDDC39',
  'ลีซู': '#009688',
  'โอก๋อง อุก๋อง ก๋อง ก๊อง': '#888888'
};

// ใช้ SVG icon เดียวกันสำหรับทุกชาติพันธุ์
const baseIconSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="{color}" width="32" height="32"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`;

// ฟังก์ชันเพื่อสร้าง custom icon URL ด้วย SVG
const createSvgIconUrl = (color) => {
  const encodedSvg = encodeURIComponent(baseIconSvg.replace('{color}', color));
  return `data:image/svg+xml;utf8,${encodedSvg}`;
};

// เปลี่ยนฟังก์ชัน getEthnicityIcon
const getEthnicityIcon = (ethnicity) => {
  if (!ethnicity) ethnicity = 'ไม่ระบุ';
  
  // กำหนดสีเริ่มต้นสำหรับชาติพันธุ์ที่ไม่ได้กำหนดไว้
  let color = '#888888';
  
  // ถ้าเป็นชาติพันธุ์ที่กำหนดไว้ ใช้สีตามที่กำหนด
  if (ethnicityColors[ethnicity]) {
    color = ethnicityColors[ethnicity];
  } else {
    // สร้างสีแบบสุ่มสำหรับชาติพันธุ์ที่ไม่ได้กำหนดไว้
    color = '#' + Math.floor(Math.random()*16777215).toString(16);
    ethnicityColors[ethnicity] = color; // เก็บสีที่สร้างไว้ใช้ต่อ
  }
  
  return {
    iconUrl: createSvgIconUrl(color),
    color: color
  };
};
  
  // เพิ่ม dependency array ให้กับ useEffect
useEffect(() => {
  // Set loading state
  setLoading(true);
  
  // API request configuration
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'X-API-KEY': 'Chaipong'  // API key as defined in your PHP script
    },
    body: JSON.stringify({}) // Empty object to fetch all villages (or add filters here)
  };
  //https://www.chaipongmap.com/read_village.php.php
  //http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_village.php
  // Fetch villages from PHP API
  fetch('https://www.chaipongmap.com/read_village.php', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response error: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.status === 'success' && data.data && data.data.features) {
        // Extract features from GeoJSON format
        setVillages(data.data.features);
        console.log(`Loaded ${data.data.features.length} villages`);
        
        // รวบรวมชาติพันธุ์ที่มีในข้อมูล
        const uniqueEthnicities = new Map();
        
        data.data.features.forEach(feature => {
          const ethnicity = feature.properties.ethnicity || 'ไม่ระบุ';
          if (!uniqueEthnicities.has(ethnicity)) {
            const ethnicityData = getEthnicityIcon(ethnicity);
            uniqueEthnicities.set(ethnicity, ethnicityData);
          }
        });
        
        // แปลงเป็น array สำหรับ legend
        const ethnicityData = Array.from(uniqueEthnicities.entries()).map(([name, data]) => ({
          name,
          color: data.color,
          iconUrl: data.iconUrl
        }));
        
        setEthnicities(ethnicityData);
        
        // ส่งข้อมูลชาติพันธุ์ขึ้นไปให้ parent component
        if (onEthnicitiesLoaded) {
          onEthnicitiesLoaded(ethnicityData);
        }
      } else {
        console.warn('Unexpected API response format:', data);
        setVillages([]);
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching village data:', error);
      setError(error.message);
      setLoading(false);
    });
}, [onEthnicitiesLoaded]); // เพิ่ม dependency array ตรงนี้

  // Render error message if fetch failed
  if (error) {
    return <div className="error-message">Error loading villages: {error}</div>;
  }

  // Render loading indicator
  if (loading) {
    return <div className="loading-indicator">Loading village data...</div>;
  }

  // Render village markers
  return (
    <>
      {villages.map((village, index) => {
        // Extract coordinates and properties from GeoJSON structure
        const [lng, lat] = village.geometry.coordinates;
        const properties = village.properties;
        const ethnicityName = properties.ethnicity || 'ไม่ระบุ';
        
        // ดึงข้อมูล icon ตามชาติพันธุ์
        const ethnicityData = getEthnicityIcon(ethnicityName);
        
        // สร้าง icon ตามชาติพันธุ์
        const customIcon = new Icon({
          iconUrl: ethnicityData.iconUrl,
          iconSize: [32, 32],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32]
        });
        
        return (
          <Marker 
            key={`village-${properties.id || index}`} 
            position={[lat, lng]}
            icon={customIcon}
          >
            <Popup>
              <div style={{fontFamily: 'Tahoma, sans-serif'}}>
                <div style={{fontWeight: 'bold', fontSize: '16px', marginBottom: '5px', color: ethnicityData.color}}>
                  {properties.village_name || 'ไม่มีชื่อ'}
                </div>
                <div>ชาติพันธุ์: <span style={{color: ethnicityData.color}}>{ethnicityName}</span></div>
                <div>ตำบล: {properties.subdistrict || '-'}</div>
                <div>อำเภอ: {properties.district || '-'}</div>
                <div>จังหวัด: {properties.province || '-'}</div>
                <div style={{marginTop: '5px', fontSize: '12px', color: '#777'}}>
                  พิกัด: {lat.toFixed(6)}, {lng.toFixed(6)}
                </div>
              </div>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
};

export default VillageMarkers;