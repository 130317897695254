import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { PiMapPinAreaFill } from "react-icons/pi";
import { BiLogOut } from "react-icons/bi";
// import Checkbox from './Checkbox';
import { AiTwotoneLeftCircle } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

const Checkbox = ({ children, checked, onChange }) => {
  return (
      <div className="flex items-center mb-4">
          <input
              type="checkbox"
              checked={checked}
              onChange={onChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              {children}
          </label>
      </div>
  );
}

const Sidebar = ({ 
  showMarker1, 
  toggleMarker1, 
  showMarker2, 
  toggleMarker2, 
}) => {

  // สร้าง state สำหรับเก็บค่าเปิด-ปิดของ Sidebar
  const [open, setOpen] = useState(true);

  // สร้าง state สำหรับเก็บค่าเปิด-ปิดของ SubMenu
  const [subMenuOpen, setSubMenuOpen] = useState(null);

  // สร้าง function สำหรับเปิด-ปิด SubMenu
  const toggleSubMenu = (index) => {
    if (!open) {
      setOpen(true);
    } else {
      setSubMenuOpen(subMenuOpen === index ? null : index);
    }
  };

  // สร้าง function สำหรับออกจากระบบ
  const navigate = useNavigate();
  const handleLogout = () => {
    if (window.confirm('คุณต้องการออกจากระบบใช่หรือไม่?')) {
      navigate('/', { replace: true });
    }
  };

  // สร้าง array สำหรับเก็บข้อมูลเมนู
  const Menus = [
    {
      title: 'ประเภทข้อมูล',
      icon: <PiMapPinAreaFill />,
      subMenus: [
        { 
          title: 'ตำแหน่งงานเทศกาล', 
          type: 'checkbox', 
          checked: showMarker1, 
          toggle: toggleMarker1,
        
        },
        {
          title: 'ตำแหน่งชาติพันธุ์', 
          type: 'checkbox', 
          checked: showMarker2, 
          toggle: toggleMarker2,
 
        },
      ],
    },
    


    {
      title: 'ออกจากระบบ',
      icon: <BiLogOut />,
      onClick: handleLogout,
    },
  ];

  return (
    <div className="flex z-40">
      <div
        // กำหนดค่าของคลาสและค่า style ของ Sidebar
        className={`${open ? 'w-60' : 'w-5'} bg-dark-purple h-screen p-5 pt-8 relative duration-500`}
      >
        <AiTwotoneLeftCircle
          size={28}
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full duration-500 ${!open ? 'rotate-180' : ''}`}
          // สร้างเงื่อนไขเมื่อคลิกที่ปุ่มเพื่อเปิด-ปิด Sidebar
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <h1 className={`text-white origin-left font-medium text-xl duration-500 ${!open && 'scale-0'}`}>
            ชั้นข้อมูล
          </h1>
        </div>
        
        
        <ul className="pt-3 flex flex-col h-[calc(100vh-120px)]">
          
          {Menus.map((Menu, index) => (
            <React.Fragment key={index}>
              <li
                className={`flex rounded-md p-5 cursor-pointer hover:bg-teal-400 text-white text-sm items-center gap-x-4 
                  ${Menu.gap ? 'mt-7' : 'mt-2'}
                
                  ${Menu.title === 'ออกจากระบบ' ? 'mt-auto' : ''}`}
                onClick={Menu.onClick ? Menu.onClick : () => toggleSubMenu(index)}
              >
                {Menu.icon}
                <span className={`flex-1 ${!open && 'hidden'} origin-left duration-200`}>
                  {Menu.title}
                </span>
                {Menu.subMenus && (
                  <BsChevronDown className={`${!open && 'hidden'} ${subMenuOpen === index ? 'rotate-180' : ''}`} />
                )}
              </li>
              {Menu.subMenus && subMenuOpen === index && (
                <ul className="pl-6">
                  {Menu.subMenus.map((subMenuItem, idx) => (
                    <li key={idx} className={`${!open && 'hidden'} flex items-center justify-between text-sm text-white py-2`}>
                      <div className="flex items-center gap-x-2 flex-1">
                        {subMenuItem.type === 'checkbox' && (
                          <>
                            <Checkbox
                              checked={subMenuItem.checked}
                              onChange={subMenuItem.toggle}
                            >
                              <span className="text-white">{subMenuItem.title}</span>
                            </Checkbox>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;