import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login'; // Adjust the path to the components folder
import Rendermap from './Components/Rendermap'; // Adjust the path to the components folder

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/rendermap" element={<Rendermap />} />
        <Route 
          path="*" 
          element={<h1>404 - Page Not Found</h1>} 
        />
      </Routes>
    </Router>
  );
}

export default App;
