import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet';

const baseIconSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="{color}" width="32" height="32"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`;

// ฟังก์ชันเพื่อสร้าง SVG URL
const createSvgIconUrl = (color) => {
  const svgContent = baseIconSvg.replace('{color}', color);
  const encodedSvg = encodeURIComponent(svgContent);
  return `data:image/svg+xml;charset=utf-8,${encodedSvg}`;
};

// สร้าง icon จาก URL ที่สร้าง
const customIcon = new Icon({
  iconUrl: createSvgIconUrl('#FF5733'),
});

const MapMarkersfestival = ({ onEdit, onMarkerChange }) => {
    const [markers, setMarkers] = useState([]);
    const API_KEY = 'Chaipong';

    // Create all handler functions
    // Helper function to safely parse coordinates
    const safeParseFloat = (value, defaultValue = 0) => {
        const parsed = parseFloat(value);
        return (!isNaN(parsed) && isFinite(parsed)) ? parsed : defaultValue;
    };

    // Helper function to validate coordinates
    const isValidCoordinate = (lat, lng) => {
        return !isNaN(lat) && !isNaN(lng) && 
               lat >= -90 && lat <= 90 && 
               lng >= -180 && lng <= 180;
    };
    const fetchactivities_markers = useCallback(async () => {
        try {
            const response = await fetch(
                //'https://www.chaipongmap.com/activities_markers.php'
                // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/activities_markers.php',
                'https://www.chaipongmap.com/activities_markers.php',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': API_KEY
                    }
                }
            );
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();
            // console.log('Raw activities data:', responseData);
            
            // ตรวจสอบว่าเป็น response แบบใหม่ที่มี status และ data
            const activitiesData = responseData.data || responseData;
            
            if (!activitiesData || !Array.isArray(activitiesData)) {
                console.error('Invalid data format received:', responseData);
                return;
            }
    
            const processedMarkers = activitiesData
                .filter(location => {
                    if (!location) return false;
                    const latitude = safeParseFloat(location.latitude);
                    const longitude = safeParseFloat(location.longitude);
                    return isValidCoordinate(latitude, longitude);
                })
                .map(location => {
                    const latitude = safeParseFloat(location.latitude);
                    const longitude = safeParseFloat(location.longitude);
                    
                    // ตรวจสอบให้แน่ใจว่ามีข้อมูลที่จำเป็นครบถ้วน
                    if (!location.location_id || !location.Location) {
                        console.warn('Missing required data for location:', location);
                        return null;
                    }
    
                    return {
                        location_id: location.location_id,
                        Location: location.Location,
                        ADM1_TH: location.ADM1_TH || '',
                        ADM2_TH: location.ADM2_TH || '',
                        ADM3_TH: location.ADM3_TH || '',
                        latitude: latitude.toFixed(6),
                        longitude: longitude.toFixed(6),
                        activities: Array.isArray(location.activities) ? location.activities.map(activity => ({
                            id: activity.id,
                            date: activity.date || '',
                            activities: activity.activities || '',
                            reference: activity.reference || ''
                        })) : []
                    };
                })
                .filter(marker => marker !== null); // กรองออกถ้ามี null
    
            // console.log('Processed Markers:', processedMarkers);
            
            if (processedMarkers.length === 0) {
                console.warn('No valid markers found after processing');
            }
    
            setMarkers(processedMarkers);
    
        } catch (error) {
            console.error('Error fetching markers:', error);
            // ไม่ต้อง setMarkers เป็น array ว่างเมื่อเกิด error
            // setMarkers([]);
        }
    }, [API_KEY]);

    

    const fetchMarkers = useCallback(async () => {
        // "http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_markers.php"
        //https://www.chaipongmap.com/read_markers.php
        try {
            const response = await fetch("https://www.chaipongmap.com/read_markers.php", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': API_KEY
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch markers');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching markers:', error);
            throw error;
        }
    }, [API_KEY]);

    

    // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_activities.php'
    //https://www.chaipongmap.com/read_activities.php
    const fetchActivities = useCallback(async () => {
        try {
            const response = await fetch(
                'https://www.chaipongmap.com/read_activities.php',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': API_KEY
                    }
                }
            );
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();
            
            // ตรวจสอบว่าเป็น response แบบใหม่ที่มี status และ data
            const activitiesData = responseData.data || responseData;
            
            if (!Array.isArray(activitiesData)) {
                throw new Error('Invalid data format received');
            }
    
            // สร้าง Map เพื่อรวบรวมกิจกรรมตาม location_id
            const locationActivitiesMap = new Map();
    
            activitiesData.forEach(activity => {
                if (!locationActivitiesMap.has(activity.location_id)) {
                    locationActivitiesMap.set(activity.location_id, {
                        location_id: activity.location_id,
                        Location: '',
                        ADM1_TH: '',
                        ADM2_TH: '',
                        ADM3_TH: '',
                        latitude: '',
                        longitude: '',
                        activities: []
                    });
                }
    
                const locationData = locationActivitiesMap.get(activity.location_id);
                locationData.activities.push({
                    id: activity.id,
                    date: activity.activity_date,
                    activities: activity.activity,
                    reference: activity.reference || ''
                });
            });
    
            const processedMarkers = Array.from(locationActivitiesMap.values());
            // console.log('Processed Markers:', processedMarkers);
            setMarkers(processedMarkers);
    
        } catch (error) {
            console.error('Error fetching markers:', error);
            setMarkers([]);
        }
    }, [API_KEY]);

    
    const handlersRef = useRef({
        
        fetchMarkers: fetchMarkers,

        fetchActivities: fetchActivities,
   

    });

    // Update ref when handlers change
    useEffect(() => {
        handlersRef.current = {
       
            fetchMarkers: fetchMarkers,
            
      
         
            fetchActivities: fetchActivities,
      
    
            fetchactivities_markers: fetchactivities_markers  // เพิ่มบรรทัดนี้
        };
    }, [
        fetchMarkers,
        fetchActivities,
        fetchactivities_markers  // เพิ่มบรรทัดนี้
    ]);

    // Pass handlers to parent only when onMarkerChange changes
    useEffect(() => {
        if (onMarkerChange) {
            onMarkerChange(handlersRef.current);
        }
    }, [onMarkerChange]);

    // Fetch initial data
    useEffect(() => {
        fetchactivities_markers();
    }, [fetchactivities_markers]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    useEffect(() => {
        // console.log('Markers state updated:', markers);
    }, [markers]);
    
    const map = useMapEvents({
        click: () => {
            map.closePopup();
        }
    });

 
    return (
        <>
            {markers.filter(marker => {
                // Additional filter to ensure valid coordinates at rendering
                const lat = parseFloat(marker.latitude);
                const lng = parseFloat(marker.longitude);
                return isValidCoordinate(lat, lng);
            }).map((marker, index) => (
                <Marker
                    key={`${marker.location_id}-${marker.Location}-${marker.latitude}-${marker.longitude}-${index}`}
                    position={[
                        parseFloat(marker.latitude), 
                        parseFloat(marker.longitude)
                    ]}
                    icon={customIcon}
                >
                    <Popup>
                        <div className="marker-popup-content">
                            <h2>สถานที่ : {marker.Location}</h2>
                            <p>ตำแหน่ง : จังหวัด {marker.ADM1_TH} อำเภอ {marker.ADM2_TH} ตำบล {marker.ADM3_TH}</p>

                            <table className="w-full mt-2 border-collapse">
                                <thead>
                                    <tr>
                                        <th className="p-2 border">วันและเวลา</th>
                                        <th className="p-2 border">กิจกรรม</th>
                                        <th className="p-2 border">อ้างอิง</th>
                             
                                    </tr>
                                </thead>
                                <tbody>
                                    {marker.activities && marker.activities.map((activity, i) => (
                                        <tr key={`${activity.date}-${activity.activities}-${i}`}>
                                            <td className="p-2 border">{activity.date}</td>
                                            <td className="p-2 border">{activity.activities}</td>
                                            <td className="p-2 border">
                                                {activity.reference ? (
                                                    <a href={activity.reference} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">คลิกที่นี่</a>
                                                ) : (
                                                    "ไม่พบ"
                                                )}
                                            </td>
                                  
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </Popup>
                </Marker>
            ))}
        </>
    );
};

export default MapMarkersfestival;