import React, { useState, useEffect, useCallback } from 'react';
import { 
  MapContainer, 
  TileLayer, 
  LayersControl, 
  GeoJSON
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MapMarkersfestival from './databaserender-festival';
import VillageMarkers from './VillageMarkers';
import EthnicityLegend from './EthnicityLegend';



const { BaseLayer } = LayersControl;

// Component สำหรับแสดง GeoJSON ขอบเขตพื้นที่ภาคเหนือตอนล่าง
const GeojsonHidePvNorthBottom = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  
  useEffect(() => {
    // อ่านไฟล์ GeoJSON
    fetch('https://atiwit2541.github.io/Atiwit.github.io/docs/data/geojson/hide_pv_north_bottom.geojson')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch GeoJSON file');
        }
        return response.json();
      })
      .then(data => {
        setGeoJsonData(data);
      })
      .catch(error => {
        console.error('Error loading GeoJSON:', error);
      });
  }, []);

  // สร้างสไตล์สำหรับ GeoJSON เป็นสีขาวไม่มี opacity
  const geoJsonStyle = {
    fillColor: '#FFFFFF', // สีขาว
    weight: 2,
    opacity: 1,
    color: '#FFFFFF', // สีขาวสำหรับเส้น
    fillOpacity: 1 // ไม่มี opacity
  };

  return geoJsonData ? (
    <GeoJSON 
      data={geoJsonData} 
      style={geoJsonStyle}
    />
  ) : null;
};

// Component สำหรับแสดง GeoJSON ขอบเขตพื้นที่ภาคเหนือตอนล่าง
const GeojsonProvinceLine = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  
  useEffect(() => {
    // อ่านไฟล์ GeoJSON
    fetch('https://atiwit2541.github.io/Atiwit.github.io/docs/data/geojson/North_bottom_v2.geojson')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch GeoJSON file');
        }
        return response.json();
      })
      .then(data => {
        setGeoJsonData(data);
      })
      .catch(error => {
        console.error('Error loading GeoJSON:', error);
      });
  }, []);

  // สร้างสไตล์สำหรับ GeoJSON เป็นสีขาวไม่มี opacity
  const geoJsonStyle = {
    fillColor: 'transparent', 
    weight: 2.5,
    opacity: 1,
    color: '#FF0000', // สีแดงเข้มสำหรับเส้นขอบ
    fillOpacity: 0 // โปร่งใส (transparent)
  };

  return geoJsonData ? (
    <GeoJSON 
      data={geoJsonData} 
      style={geoJsonStyle}
    />
  ) : null;
};

const MapComponent = React.forwardRef(({ showMarker1, showMarker2 }, ref) => {
  // กำหนดขอบเขตสูงสุดที่สามารถเลื่อนได้ (ละติจูด, ลองจิจูด) - กว้างขึ้น
  const maxBounds = [
    [14.0, 97.0], // ขอบล่างซ้าย (Southwest) - ขยายให้กว้างขึ้น
    [19.5, 103.0]  // ขอบบนขวา (Northeast) - ขยายให้กว้างขึ้น
  ];

  // เพิ่ม state สำหรับเก็บข้อมูลชาติพันธุ์
  const [ethnicities, setEthnicities] = useState([]);
  // ฟังก์ชันรับข้อมูลชาติพันธุ์จาก VillageMarkers
  const handleEthnicitiesLoaded = useCallback((data) => {
    setEthnicities(data);
  }, []);


  return (
    <MapContainer
      ref={ref}
      style={{ height: "100%", width: "100%", zIndex: "30" }}
      center={[16.7619778601, 100.1865224515]}
      zoom={8} // ปรับซูมให้เห็นทั้งภาคเหนือตอนล่าง
      zoomControl={false}
      maxBounds={maxBounds}
      maxBoundsViscosity={1.0} // ค่า 1.0 คือไม่สามารถเลื่อนออกนอกขอบเขตได้เลย
      minZoom={7} // กำหนดระดับซูมต่ำสุด
    >
     
      <LayersControl position="topright">
        <BaseLayer name="OpenStreetMap" checked>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        <BaseLayer name="Alidade Satellite">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          />
        </BaseLayer>
        <GeojsonHidePvNorthBottom />
        <GeojsonProvinceLine />
     
        {showMarker1 && <MapMarkersfestival />}
        <EthnicityLegend ethnicities={ethnicities} visible={showMarker2} />
        {showMarker2 && <VillageMarkers onEthnicitiesLoaded={handleEthnicitiesLoaded} />}

      </LayersControl>
    </MapContainer>
  );
});

export default MapComponent;