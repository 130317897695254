import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar'; // Adjust the import path as necessary
import MapComponent from './MapContainer'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';

const Rendermap = () => {
  const [showMarker1, setShowMarker1] = useState(false);
  const [showMarker2, setShowMarker2] = useState(true);
  const navigate = useNavigate();
  const mapRef = useRef(); // สร้าง ref สำหรับ MapContainer

  const toggleMarker1 = () => setShowMarker1(!showMarker1);
  const toggleMarker2 = () => setShowMarker2(!showMarker2);


  // Simulated authentication check (replace with your own logic)
  const isAuthenticated = true; // Change to your authentication logic

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true }); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow">
        <Sidebar 
          showMarker1={showMarker1} 
          toggleMarker1={toggleMarker1} 
          showMarker2={showMarker2} 
          toggleMarker2={toggleMarker2} 
        />
        <div className="flex-grow relative">
          <MapComponent ref={mapRef} showMarker1={showMarker1}  showMarker2={showMarker2}  />
          <div className="absolute bottom-4 right-4 z-50">
   
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rendermap;